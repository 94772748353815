// import node_modules
import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

// import types
import { QuoteColor, QuoteColorEnum, SectionColor, SectionColorEnum } from '../../types';

// import component
import Column from './Column';
import Section from './Section';
import StorylinerImage from './StorylinerImage';

// import styles
import styles from './Quote.module.css';

// Quote
export const Quote: React.FunctionComponent<{
  bgColor: SectionColor;
  color: QuoteColor;
  colorDark: QuoteColor;
  image: string;
  jobTitle: string;
  name: string;
  quote: string;
  snippet: string;
  snippetDark: string;
}> = ({ bgColor, color, colorDark, image, jobTitle, name, quote, snippet, snippetDark }) => (
  <Section bgColor={bgColor}>
    <Column cols={3}>
      <div
        className={classNames(
          styles['image-canvas'],
          snippet && styles[snippet],
          snippetDark && styles[`${snippetDark}-dark`],
        )}
      >
        <StorylinerImage imageId={image} isRounded={false} height={512} title={name} width={512} />
      </div>
    </Column>
    <Column cols={2}>
      <div className={styles['quote-container']}>
        <div
          className={classNames(
            styles['quote-marks'],
            color && styles[`is-${color}`],
            colorDark && styles[`is-${colorDark}-dark`],
          )}
        />
        <blockquote
          className={classNames(
            styles['quote-text'],
            color && styles[`is-${color}`],
            colorDark && styles[`is-${colorDark}-dark`],
          )}
          dangerouslySetInnerHTML={{ __html: quote }}
        />
        <div
          className={classNames(
            styles['quote-cite'],
            color && styles[`is-${color}`],
            colorDark && styles[`is-${colorDark}-dark`],
          )}
        >
          <span className={styles['quote-cite-name']}>{name}</span>
          <span className={styles['quote-cite-description']}>{jobTitle}</span>
        </div>
      </div>
    </Column>
  </Section>
);

Quote.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(SectionColorEnum)).isRequired,
  color: PropTypes.oneOf(Object.values(QuoteColorEnum)).isRequired,
  colorDark: PropTypes.oneOf(Object.values(QuoteColorEnum)).isRequired,
  image: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  snippet: PropTypes.string.isRequired,
  snippetDark: PropTypes.string.isRequired,
};

export default Quote;
